var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "DialogReportPaid__Component" }, [
    _c("div", { staticClass: "modal-card" }, [
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [_vm._v("סטטוח דוח")]),
        _c("button", {
          staticClass: "delete",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        })
      ]),
      _c("section", { staticClass: "modal-card-body" }, [
        _c("h1", [_vm._v("נא לבחור את הסטטוס אשר ישוייך אל הדוחות המסומנים")]),
        _c(
          "div",
          { staticClass: "DialogReportPaid__Component__Loading" },
          [
            _c(
              "v-select",
              {
                attrs: {
                  dir: "rtl",
                  label: "Text",
                  options: _vm.data,
                  "append-to-body": "",
                  clearable: true,
                  placeholder: "בחר סטטוס מהרשימה",
                  reduce: function(item) {
                    return item.Value
                  }
                },
                on: { input: _vm.setSelected }
              },
              [
                _c(
                  "div",
                  { attrs: { slot: "no-options" }, slot: "no-options" },
                  [_vm._v("לא נמצאו ערכים דומים")]
                )
              ]
            )
          ],
          1
        )
      ]),
      _c(
        "footer",
        { staticClass: "modal-card-foot" },
        [
          _c("b-button", {
            attrs: {
              loading: _vm.isLoading,
              disabled: !_vm.statusId,
              label: "שמור",
              type: "is-primary"
            },
            on: { click: _vm.save }
          }),
          _c("b-button", {
            attrs: { disabled: _vm.isLoading, label: "סגור" },
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }