import axios from 'axios';

const BASE_URL = process.env.VUE_APP_APIURL;

const search = (model, cancelToken) => axios.post(`${BASE_URL}/api/TollRoadReports/Search`, model, {
    cancelToken,
});
const exportXsl = (model, isGrouped) => axios.post(`${BASE_URL}/api/TollRoadReports/Search/xlsx?isGrouped=${isGrouped}`, model, {
    responseType: 'blob',
});
const setStatus = (model) => axios.put(`${BASE_URL}/api/TollRoadReports/Status`, model);

export default {
    search,
    exportXsl,
    setStatus,
};
