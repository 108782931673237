<template>
  <div class="DialogReportPay__Component">
       <div class="modal-card" :class="{ready: !isLoading}">
        <header class="modal-card-head">
            <p class="modal-card-title">תשלום דוחות</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
        </header>
        <section class="modal-card-body">
            <div class="DialogReportPay__Component__Loading">
                <transition name="fade">
                    <h1 v-if="showLoading">
                    {{loading[sentenceIndex]}}
                    </h1>
                </transition>
                <div>
                    <b-loading :is-full-page="false"
                        v-model="isLoading" :can-cancel="false"></b-loading>
                </div>
            </div>
        </section>
        <footer  class="modal-card-foot">
            <transition name="fade">
                <div v-if="!isLoading">
                    <b-button
                    label="שלם"
                    type="is-primary" />
                    <b-button
                        label="סגור"
                        @click="$emit('close')" />
                </div>
            </transition>
        </footer>
    </div>
  </div>
</template>

<script>
export default {
    name: 'DialogReportPay',
    computed: {
        loading() {
            return [
                'מתחבר למסוף',
                'אנא המתן, הפעולה עשויה להמשך קצת יותר מהרגיל',
                'נעדכן אותך ברגע שמסך התשלום יהיה מוכן',
            ];
        },
    },
    mounted() {
        this.replaceLoadSentence();
        setTimeout(() => {
            this.isLoading = false;
            this.showLoading = false;
            clearInterval(this.interval);
        }, 5000);
    },
    data() {
        return {
            isLoading: true,
            sentenceIndex: 0,
            showLoading: true,
            interval: null,
        };
    },
    methods: {
        replaceLoadSentence() {
            this.interval = setInterval(() => {
                this.showLoading = false;
                setTimeout(() => {
                    this.showLoading = true;
                }, 0);
                this.sentenceIndex += 1;
                if (this.sentenceIndex >= this.loading.length) {
                    this.sentenceIndex = 0;
                }
            }, 4000);
        },
    },
};
</script>

<style scoped lang="scss">
.modal-card {
    transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1);
    width: 400px;
    height: 300px;
}
.modal-card.ready {
    height: 500px;
    width: 700px;
}
.modal-card-title {
    font-size: 20px;
}
.modal-card-body {
    position: relative;
}
.DialogReportPay__Component__Loading {
    h1 {
        text-align: center;
        margin-bottom: 30px;
        font-size: 20px;
    }
    & > div {
        position: relative;
        height: 50px;
    }
}
.fade-enter-active, .fade-leave-active {
    transition: opacity 2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
