<template>
  <div class="DialogReportPaid__Component">
    <NewActivity
      ref="taskActivity"
      v-if="isModalOpen"
      type="Task"
      :propPriority="taskReminderObj.Priority"
      :propTitle="getTitle"
      :propReminder="getReminder"
      :propAssignedUserId="taskReminderObj.AssignedUserId"
      show-title="true"
      @onClose="onTaskModelClose"
      @onSave="saveTask"
      :propBody="taskReminderObj.Body"
      :propisAdminWithoutProfileId="isAdminWithoutProfileId"
    />
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">שינוי סטטוס הסבת חברה</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <div class="DialogReportPaid__Component__Loading">
          <b-field label="אסמכתה/הערה/דואר רשום">
            <b-input v-model="reference"></b-input>
          </b-field>
          <b-field
            label="סטטוס הסבה"
            :type="!isStatusValid ? 'is-danger' : ''"
            :message="!isStatusValid ? 'יש לבחור סטטוס הסבה' : ''"
          >
            <v-select
              dir="rtl"
              label="Text"
              :options="data"
              append-to-body
              :clearable="true"
              :placeholder="'בחר סטטוס מהרשימה'"
              :reduce="(item) => item.Value"
              @input="setSelected"
            >
              <div slot="no-options">לא נמצאו ערכים דומים</div>
            </v-select>
          </b-field>
          <div class="activity-container" v-if="statusId">
            <b-checkbox
              :loading="isLoading"
              v-model="isCreateTask"
              label=""
              type="is-link is-light"
              class="checkbox"
              @click.native="onIsCreateTask"
            >
              צור משימה
            </b-checkbox>
            <a
              @click="
                isCreateTask = true;
                isModalOpen = true;
              "
              v-if="
                typeof taskReminderObj === 'object' && taskReminderObj !== null
              "
              >ערוך</a
            >
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <b-button
          :loading="isLoading"
          @click="save"
          label="שמור"
          type="is-primary"
        />
        <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
      </footer>
    </div>
  </div>
</template>

<script>
import TransferStatusesService from "@/services/TransferStatusesService";
import LeasingCompaniesService from "@/services/LeasingCompaniesService";
import EntityItemsService from "@/services/EntityItemsService";
import vSelect from "vue-select";
import "vue-select/src/scss/vue-select.scss";
import {
  SnackbarProgrammatic as Snackbar,
  ToastProgrammatic as Toast,
} from "buefy";
import { mapState } from "vuex";
import NewActivity from "./NewActivity.vue";

export default {
  name: "DialogChangeTransferStatusCompanyAdmin",
  props: ["reportsNumber", "transferIds", "onSuccess", "store"],
  components: {
    vSelect,
    NewActivity,
  },
  computed: {
    ...mapState("profile", ["Id"]),

    isStatusValid() {
      if (this.submitted) {
        return this.statusId !== undefined;
      }
      return true;
    },
    isAdminWithoutProfileId() {
      return !this.store.state.auth.profileId && this.store.state.auth?.user?.claims?.isAdmin === "True";
    },
    getTitle() {
      const opt = this.data.find((res) => res.Value === this.statusId);
      const str = this.reportsNumber.join();
      return `סטטוס שונה ל${opt.Text}, עבור דוחות: ${str}`;
    },
    getReminder() {
      const date = new Date();
      return date.setDate(date.getDate() + 1);
    },
    // getAssignedUserId() {
    //   // console.log("this:", this);
    //   // console.log("this.Id:", this.Id);
    //   return 8;
    //   // return this.Id;
    //   // return this.$store.state.profile?.Id;
    // },
  },
  created() {
    TransferStatusesService.get().then((r) => {
      this.data = r;
    });
  },
  data() {
    return {
      submitted: false,
      isLoading: false,
      statusId: null,
      reference: null,
      data: [],
      isCreateTask: false,
      isModalOpen: false,
      taskReminderObj: {
        Body: null,
        AssignedUserId: "669d7841-9aa9-4977-993f-52fee434d1d8",
        Priority: 1,
      },
    };
  },
  methods: {
    saveTask(res) {
      this.isModalOpen = false;
      this.isTask = true;
      this.taskReminderObj = { ...res };
      this.taskReminderObj.DueDate = new Date(this.taskReminderObj.DueDate);
      this.taskReminderObj.EmailReminderDate = new Date(
        this.taskReminderObj.EmailReminderDate
      );
    },
    setSelected(value) {
      this.statusId = value;
    },
    save() {
      this.submitted = true;
      if (this.isStatusValid) {
        this.isLoading = true;
        LeasingCompaniesService.setTransferStatusFromItemIds({
          ItemIds: this.transferIds,
          TransferStatus: this.statusId,
          Reference: this.reference,
        })
          .then(({ data }) => {
            if (this.isCreateTask) {
              const successRepoets = data.filter((r) => r.Success);
              const reqs = successRepoets.map((r) =>
                EntityItemsService.addTask(
                  "Report",
                  r.ReportId,
                  this.taskReminderObj
                )
              );
              Promise.all(reqs).then(() => {
                this.$emit("close");
                if (this.onSuccess) this.onSuccess();
                successRepoets.length === this.transferIds.length
                  ? Toast.open({
                      type: "is-success",
                      message: "הפעולה בוצעה!",
                      duration: 4000,
                    })
                  : Toast.open({
                      type: "is-danger",
                      message:
                        "קרתה תקלה, לא כל הדוחות נשמרו!<br />לדוחות שלא נשמרו לא נוצרה משימה.",
                      duration: 4000,
                    });
              });
            } else {
              this.$emit("close");
              if (this.onSuccess) this.onSuccess();
              data.every((r) => r.Success)
                ? Toast.open({
                    type: "is-success",
                    message: "הפעולה בוצעה!",
                    duration: 4000,
                  })
                : // Toast.open({
                  //     type: "is-danger",
                  //     message: "קרתה תקלה, לא כל הדוחות נשמרו!",
                  //     duration: 4000,
                  //   });
                  Snackbar.open({
                    message: "קרתה תקלה, לא כל הדוחות נשמרו!",
                    type: "is-danger ",
                    position: "is-top",
                    actionText: "אישור",
                    indefinite: true,
                  });
            }
          })
          .catch(() => {
            Toast.open({
              type: "is-danger",
              message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
              duration: 8000,
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    onIsCreateTask() {
      if (!this.isCreateTask) {
        this.isModalOpen = true;
      }
    },
    onTaskModelClose() {
      this.isModalOpen = false;
      this.isCreateTask = false;
    },
  },
};
</script>

<style lang="scss">
.DialogReportPaid__Component__Loading {
  .checkbox {
    margin: 0 !important;
    span:first-of-type {
      margin: 0 0 0 5px !important;
    }
  }
  .activity-container {
    display: flex;
  }
}
</style>
