<template>
    <div class="DialogUpdateNote__Component">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">עדכון הערה </p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">
                <div class="DialogUpdateNote__Component__Loading">
                    <b-field label="הערה" :type="!isValidReference ? 'is-danger' : ''"
                        :message="!isValidReference ? 'יש להזין הערה' : ''">
                        <b-input v-model="note"></b-input>
                    </b-field>
                </div>
            </section>
            <footer class="modal-card-foot">
                <b-button :loading="isLoading" @click="save" label="שמור" type="is-primary" />
                <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
            </footer>
        </div>
    </div>
</template>

<script>
import EntityItemsService from "@/services/EntityItemsService";
import { ToastProgrammatic as Toast } from "buefy";

export default {
    name: "DialogUpdateNote",
    props: ["tasks", "onSuccess"],
    computed: {
        isValidReference() {
            if (this.submitted) {
                return this.note;
            }
            return true;
        },
    },
    data() {
        return {
            isLoading: false,
            submitted: false,
            note: null,
        };
    },
    methods: {
        save() {
            this.submitted = true;
            if (this.isValidReference) {
                this.isLoading = true;
                const reqs = this.tasks.map((t) =>
                    EntityItemsService.addComment(
                        "Report",
                        t.EntityId,
                        t.Id,
                        { Body: this.note }
                    )
                );
                Promise.all(reqs)
                    .then((r) => {
                        this.tasks.map((t, idx) => t.Comments.push(r[idx].Body));
                        this.$emit("close");
                        if (this.onSuccess) this.onSuccess();
                        Toast.open({
                            type: "is-success",
                            message: "הפעולה בוצעה!",
                            duration: 4000,
                        });
                    })
                    .catch(() => {
                        Toast.open({
                            type: "is-danger",
                            message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
                            duration: 8000,
                        });
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            }
        },
    },
};
</script>

<style lang="scss">
.datetime .dropdown-content {
    width: 25%;

    .field-body .field {
        flex-direction: row-reverse;
    }
}
</style>
