/* eslint-disable consistent-return */
import {
    DialogProgrammatic as Dialog,
    ToastProgrammatic as Toast,
    LoadingProgrammatic as Loading,
    ModalProgrammatic as Modal,
} from 'buefy';
import DialogReportPay from '@/components/DialogReportPay.vue';
import DialogTollRoadStatus from '@/components/DialogTollRoadStatus.vue';
import AssignGroupsToLessons from '@/components/AssignGroupsToLessons.vue';
import AssignGroupsToStudents from '@/components/AssignGroupsToStudents.vue';
import DialogUpdateNote from '@/components/DialogUpdateNote.vue';
import DialogUpdateTask from '@/components/DialogUpdateTask.vue';
import DialogReportDriverAssign from '@/components/DialogReportDriverAssign.vue';
import DialogAddTask from '@/components/DialogAddTask.vue';
import DialogChangeTransferStatusCompany from '@/components/DialogChangeTransferStatusCompany.vue';
import DialogChangeTransferStatusCompanyAdmin from '@/components/DialogChangeTransferStatusCompanyAdmin.vue';
import DialogChangeTransferStatusCompanyAdminLimited from '@/components/DialogChangeTransferStatusCompanyAdminLimited.vue';
import DialogChangeTransferStatusDriverAdmin from '@/components/DialogChangeTransferStatusDriverAdmin.vue';
import DialogChangeTransferStatusDriver from '@/components/DialogChangeTransferStatusDriver.vue';
import ReportStatusManageWidget from '@/components/Widgets/ReportStatusManageWidget.vue';
import DialogSign from '@/components/DialogSign.vue';
import { saveAs } from 'file-saver';
import LeasingCompaniesService from '@/services/LeasingCompaniesService';
import printer from '../plugins/printer';
import ReportService from './ReportService';
import AccidentService from './AccidentService';
import DriverService from './DriverService';
import VehicleService from './VehicleService';
import store from '../store';
import LessonService from './LessonService';

const course = JSON.parse(localStorage.getItem('course'));

const actions = {
    approvePay: {
        Id: 'ApprovePay',
        Label: 'אישור דוחות לתשלום',
        icon: 'checkbox-multiple-blank-circle',
        click: (reports, onSuccess) => {
            Dialog.confirm({
                message: 'האם ברצונך לאשר את דוחות אלה לתשלום?',
                confirmText: 'כן',
                cancelText: 'לא',
            })
                .then(({ result }) => {
                    if (result) {
                        const loading = Loading.open({
                            isFullPage: true,
                        });
                        ReportService
                            .approvePayment(reports.map((r) => r.Id))
                            .then(() => {
                                if (onSuccess) onSuccess();
                                Toast.open({
                                    type: 'is-success',
                                    message: 'הדוחות אושרו לתשלום!',
                                    duration: 4000
                                });
                            })
                            .catch(() => {
                                Toast.open({
                                    type: 'is-danger',
                                    message: 'לא היה ניתן לבצע את הפעולה, נסה לאשר דוחות שוב.',
                                    duration: 8000
                                });
                            })
                            .finally(() => {
                                loading.close();
                            });
                    }
                });
        },
    },
    disApprovePay: {
        Id: 'DisApprovePay',
        Label: 'ביטול אישור דוחות לתשלום',
        icon: 'checkbox-multiple-blank-circle-outline',
        click: (reports, onSuccess) => {
            Dialog.confirm({
                message: 'האם ברצונך לבטל את דוחות אלה מאישור לתשלום?',
                confirmText: 'כן',
                cancelText: 'לא',
            })
                .then(({ result }) => {
                    if (result) {
                        const loading = Loading.open({
                            isFullPage: true,
                        });
                        ReportService
                            .disapprovePayment(reports.map((r) => r.Id))
                            .then(() => {
                                if (onSuccess) onSuccess();
                                Toast.open({
                                    type: 'is-success',
                                    message: 'הדוחות בוטלו מאישור לתשלום!',
                                    duration: 4000,
                                });
                            })
                            .catch(() => {
                                Toast.open({
                                    type: 'is-danger',
                                    message: 'לא היה ניתן לבצע את הפעולה, נסה לאשר דוחות שוב.',
                                    duration: 8000,
                                });
                            })
                            .finally(() => {
                                loading.close();
                            });
                    }
                });
        },
    },
    payReports: {
        Id: 'PayReports',
        Label: 'תשלום דוחות',
        icon: 'credit-card-check',
        click: () => {
            Modal.open({
                component: DialogReportPay,
                canCancel: false,
                hasModalCard: true,
                trapFocus: true,
            });
        },
    },
    assignGroupsToLessons: {
        Id: 'AssignGroupsToLessons',
        Label: 'שיוך קבוצות ליחידות לימוד',
        icon: 'cash-check',
        click: (groups, onSuccess) => {
            Modal.open({
                component: AssignGroupsToLessons,
                props: {
                    groups: groups.map((r) => r.id),
                    onSuccess,
                    store
                },
                canCancel: false,
                hasModalCard: true,
                trapFocus: true,
            });
        },
    },
    assignGroupsToStudents: {
        Id: 'AssignGroupsToStudents',
        Label: 'שיוך תלמידים לקבוצה',
        icon: 'credit-card-check',
        click: (groups, onSuccess) => {
            Modal.open({
                component: AssignGroupsToStudents,
                props: {
                    groups: groups.map((r) => r.id),
                    onSuccess,
                    store
                },
                canCancel: false,
                hasModalCard: true,
                trapFocus: true,
            });
        },
    },
    assignEscortsToStudents: {
        Id: 'AssignEscortsToStudents',
        Label: 'שיוך מלווים לקבוצה',
        icon: 'credit-card-check',
        click: (groups, onSuccess) => {
            Modal.open({
                component: AssignGroupsToStudents,
                props: {
                    groups: groups.map((r) => r.id),
                    onSuccess,
                    store,
                    isEscort: true
                },
                canCancel: false,
                hasModalCard: true,
                trapFocus: true,
            });
        },
    },
    updateNote: {
        Id: 'UpdateNote',
        Label: 'עדכון הערה',
        icon: 'pencil',
        click: (tasks, onSuccess) => {
            Modal.open({
                component: DialogUpdateNote,
                props: {
                    tasks: tasks.map((t) => t),
                    onSuccess,
                },
                canCancel: false,
                hasModalCard: true,
                trapFocus: true,
            });
        },
    },
    updateTask: {
        Id: 'UpdateTask',
        Label: 'עדכון משימה',
        icon: 'pencil',
        click: (tasks, onSuccess) => {
            Modal.open({
                component: DialogUpdateTask,
                props: {
                    tasks: tasks.map((t) => t),
                    onSuccess,
                },
                canCancel: false,
                hasModalCard: true,
                trapFocus: true,
            });
        },
    },
    addTask: {
        Id: 'AddTask',
        Label: 'הוספת משימה/הערה',
        icon: 'plus',
        click: (reports, onSuccess) => {
            Modal.open({
                component: DialogAddTask,
                props: {
                    reports: reports.map((t) => t),
                    onSuccess,
                    store
                },
                canCancel: false,
                hasModalCard: true,
                trapFocus: true,
            });
        },
    },
    driverAssign: {
        Id: 'DriverAssign',
        Label: 'שיוך לנהג',
        icon: 'account-switch-outline',
        click: (reports, onSuccess) => {
            Modal.open({
                component: DialogReportDriverAssign,
                props: {
                    reports: reports.map((r) => r.Id),
                    currentDriver: reports.length && reports[0].Driver ? {
                        driverId: reports[0].Driver.Id,
                        isDriverPaid: reports[0].IsPaidByDriver,
                        showAmount: !!reports[0].DriverAmount,
                        amount: reports[0].DriverAmount
                    } : undefined,
                    onSuccess,
                },
                canCancel: false,
                hasModalCard: true,
                trapFocus: true,
            });
        },
    },
    changeTransferStatusCompany: {
        Id: 'ChangeTransferStatusCompany',
        Label: 'שינוי סטטוס הסבה',
        icon: 'account-arrow-right-outline',
        click: (reports, onSuccess) => {
            Modal.open({
                component: DialogChangeTransferStatusCompany,
                props: {
                    reportsNumber: reports.map((r) => r.ReportNumber),
                    reports: reports.map((r) => r.Id),
                    onSuccess,
                },
                canCancel: false,
                hasModalCard: true,
                trapFocus: true,
            });
        },
    },
    changeTransferStatusCompanyAdmin: {
        Id: 'changeTransferStatusCompanyAdmin',
        Label: 'שינוי סטטוס הסבה',
        icon: 'account-arrow-right-outline',
        click: (reports, onSuccess) => {
            Modal.open({
                component: DialogChangeTransferStatusCompanyAdmin,
                props: {
                    reportsNumber: reports.map((r) => r.ReportNumber),
                    // reports: reports.map((r) => r.Id),
                    transferIds: reports.map((r) => r.TransferBatchItemId),
                    onSuccess,
                    store
                },
                canCancel: false,
                hasModalCard: true,
                trapFocus: true,
            });
        },
    },
    changeTransferStatusCompanyAdminLimited: {
        Id: 'changeTransferStatusCompanyAdminLimited',
        Label: 'שינוי סטטוס הסבה',
        icon: 'account-arrow-right-outline',
        click: (reports, onSuccess) => {
            Modal.open({
                component: DialogChangeTransferStatusCompanyAdminLimited,
                props: {
                    reportsNumber: reports.map((r) => r.ReportNumber),
                    // reports: reports.map((r) => r.Id),
                    transferIds: reports.map((r) => r.TransferBatchItemId),
                    onSuccess,
                    store
                },
                canCancel: false,
                hasModalCard: true,
                trapFocus: true,
            });
        },
    },
    changeTransferStatusDriverAdmin: {
        Id: 'changeTransferStatusDriverAdmin',
        Label: 'שינוי סטטוס הסבה נהג',
        icon: 'account-arrow-left-outline',
        click: (reports, onSuccess) => {
            Modal.open({
                component: DialogChangeTransferStatusDriverAdmin,
                props: {
                    reportsNumber: reports.map((r) => r.ReportNumber),
                    reports: reports.map((r) => r.Id),
                    onSuccess,
                    store
                },
                canCancel: false,
                hasModalCard: true,
                trapFocus: true,
            });
        },
    },
    changeTransferStatusDriver: {
        Id: 'ChangeTransferStatusDriver',
        Label: 'שינוי סטטוס הסבה נהג',
        icon: 'account-arrow-left-outline',
        click: (reports, onSuccess) => {
            Modal.open({
                component: DialogChangeTransferStatusDriver,
                props: {
                    reportsNumber: reports.map((r) => r.ReportNumber),
                    reports: reports.map((r) => r.Id),
                    onSuccess,
                    store
                },
                canCancel: false,
                hasModalCard: true,
                trapFocus: true,
            });
        },
    },
    changeReportStatus: {
        Id: 'ChangeReportStatus',
        Label: 'ניהול סטטוס דוח',
        icon: 'flag-variant-outline',
        click: (reports, onSuccess) => {
            Modal.open({
                component: ReportStatusManageWidget,
                props: {
                    reports: reports.map((r) => r.Id),
                    onSuccess,
                },
                canCancel: false,
                hasModalCard: true,
                trapFocus: true,
            });
        },
    },

    removeFromProfile: {
        Id: 'RemoveFromProfile',
        Label: 'גריעת דוח מפרופיל',
        icon: 'clipboard-remove-outline',
        click: (reports, onSuccess) => {
            Dialog.confirm({
                message: 'האם ברצונך לגרוע דוחות אלה מהפרופיל?',
                confirmText: 'כן',
                cancelText: 'לא',
            })
                .then(({ result }) => {
                    if (result) {
                        const loading = Loading.open({
                            isFullPage: true,
                        });
                        ReportService
                            .removeFromProfile(reports.map((r) => r.Id))
                            .then(() => {
                                if (onSuccess) onSuccess();
                                Toast.open({
                                    type: 'is-success',
                                    message: 'הדוחות אושרו לתשלום!',
                                    duration: 4000
                                });
                            })
                            .catch((err) => {
                                let message = 'לא היה ניתן לבצע את הפעולה, נסה לאשר דוחות שוב.';
                                if (err.response?.data?.Message === 'Some or all reports were not excluded. Please note that reports must have alert of type "not mine".') message = 'חלק מהדוחות לא נגרעו. שים לב שניתן לגרוע רק דוחות עם התראה "לא שלי".';
                                Toast.open({
                                    type: 'is-danger',
                                    message,
                                    duration: 8000
                                });
                            })
                            .finally(() => {
                                loading.close();
                            });
                    }
                });
        },
    },
    deleteGroups: {
        Id: 'DeleteGroups',
        Label: 'מחיקת קבוצות',
        icon: 'flag-variant-outline',
        click: (groups, onSuccess) => {
            Dialog.confirm({
                message: 'האם אתה בטוח שברצונך למחוק את הקבוצות המסומנות?',
                onConfirm: () => {
                    LessonService.deleteGroups(course.id, {
                        groupIds: groups.map((g) => g.id)
                    }).then(() => {
                        Toast.open({
                            type: 'is-success',
                            message: 'המחיקה בוצעה בהצלחה',
                            duration: 10000,
                        });
                        if (onSuccess) onSuccess();
                    });
                },
                confirmText: 'בטוח!',
                cancelText: 'בטל',
            });
        },
    },
    reportsAlertsGenerator: {
        Id: 'ReportsAlertsGenerator',
        Label: 'סריקות התראות',
        icon: 'flag-variant-outline',
        click: (reports) => {
            Dialog.confirm({
                message: 'האם אתה בטוח שברצונך להריץ סריקת התראות לדוחות המסומנים?',
                onConfirm: () => {
                    reports.map((r) => r.ReportNumber)
                        .forEach(((i) => {
                            ReportService
                                .generateAlerts({
                                    reportNumber: i,
                                });
                        }));
                    Toast.open({
                        type: 'is-success',
                        message: 'הסריקה החלה... התהליך עשוי להמשך מספר דקות. ניתן להמשיך בעבודה',
                        duration: 10000,
                    });
                },
                confirmText: 'בטוח!',
                cancelText: 'בטל',
            });
        },
    },
    postRegisteredMail: {
        Id: 'PostRegisteredMail',
        Label: 'שליחה בדואר רשום',
        icon: 'email',
        click: (reports, onSuccess) => {
            Dialog.prompt({
                message: 'אנא הזינו את מספר אישור השליחה',
                confirmText: 'שלח',
                cancelText: 'סגור',
            })
                .then(({ result }) => {
                    if (result) {
                        const loading = Loading.open({
                            isFullPage: true,
                        });
                        LeasingCompaniesService
                            .setTransferStatus({
                                ReportIds: reports.map((r) => r.Id),
                                Reference: result,
                                TransferStatus: 'SentByMail',
                            })
                            .then((r) => {
                                // eslint-disable-next-line array-callback-return
                                const arrFails = r.data.filter((item) => { if (!item.Success) { return item.ReportNumber; } });
                                onSuccess(); // execute search again
                                if (arrFails.length > 0) {
                                    Dialog.alert(`<div style="max-height:300px">כל הדוחות נשמרו בהצלחה מלבד ${arrFails.map((currFail) => `<br/>${currFail.ReportNumber}`).join('')}</div>`);
                                } else {
                                    Toast.open({
                                        type: 'is-success',
                                        message: 'אישור שליחה נשמר בהצלחה!',
                                        duration: 4000,
                                    });
                                }
                            })
                            .catch(() => {
                                Toast.open({
                                    type: 'is-danger',
                                    message: 'לא היה ניתן לבצע את הפעולה, נסה שוב.',
                                    duration: 8000,
                                });
                            })
                            .finally(() => {
                                loading.close();
                            });
                    }
                });
        },
    },
    driverPostRegisteredMail: {
        Id: 'DriverPostRegisteredMail',
        Label: 'שליחה בדואר רשום',
        icon: 'email',
        click: (reports, onSuccess) => {
            Dialog.prompt({
                message: 'אנא הזינו את מספר אישור השליחה',
                confirmText: 'שלח',
                cancelText: 'סגור',
            })
                .then(({ result }) => {
                    if (result) {
                        const loading = Loading.open({
                            isFullPage: true,
                        });
                        DriverService
                            .setTransferStatus({
                                ReportIds: reports.map((r) => r.Id),
                                Reference: result,
                                TransferStatus: 'SentByMail',
                            })
                            .then((r) => {
                                // eslint-disable-next-line array-callback-return
                                const arrFails = r.data.filter((item) => { if (!item.Success) { return item.ReportNumber; } });
                                onSuccess(); // execute search again
                                if (arrFails.length > 0) {
                                    Dialog.alert(`<div style="max-height:300px">כל הדוחות נשמרו בהצלחה מלבד ${arrFails.map((currFail) => `<br/>${currFail.ReportNumber}`).join('')}</div>`);
                                } else {
                                    Toast.open({
                                        type: 'is-success',
                                        message: 'אישור שליחה נשמר בהצלחה!',
                                        duration: 4000,
                                    });
                                }
                            })
                            .catch(() => {
                                Toast.open({
                                    type: 'is-danger',
                                    message: 'לא היה ניתן לבצע את הפעולה, נסה שוב.',
                                    duration: 8000,
                                });
                            })
                            .finally(() => {
                                loading.close();
                            });
                    }
                });
        },
    },
    printTransfers: {
        Id: 'PrintTransfers',
        Label: 'הדפסה',
        icon: 'printer',
        click: (reports) => {
            Dialog.confirm({
                message: 'האם ברצונך להדפיס את הדוחות המסומנים?',
                confirmText: 'כן',
                cancelText: 'לא',
            })
                .then(({ result }) => {
                    if (result) {
                        const loading = Loading.open({
                            isFullPage: true,
                        });

                        LeasingCompaniesService
                            .printTransfers(reports.map((item) => item.Id))
                            .then((r) => {
                                printer.printHtml(r.data.Html, null, null, () => {
                                    Dialog.confirm({
                                        message: 'האם לסמן את הדוחות כנשלחו להדפסה?',
                                        confirmText: 'כן',
                                        cancelText: 'לא',
                                    })
                                        .then((toPrint) => {
                                            if (toPrint.result) {
                                                LeasingCompaniesService
                                                    .setTransferStatus({
                                                        TransferStatus: 'Printed',
                                                        ReportIds: reports.map((i) => i.Id),
                                                    }).then(() => {
                                                        Toast.open({
                                                            type: 'is-success',
                                                            message: 'סטטוס הדוחות שונה להודפסו!',
                                                            duration: 4000,
                                                        });
                                                    });
                                            }
                                        });
                                });
                            })
                            .catch(() => {
                                Toast.open({
                                    type: 'is-danger',
                                    message: 'לא היה ניתן לבצע את הפעולה, נסה שוב.',
                                    duration: 8000,
                                });
                            })
                            .finally(() => {
                                loading.close();
                            });
                    }
                });
        },
    },
    downloadFiles: {
        Id: 'downloadFiles',
        Label: 'הדפסת קבצי הסבה',
        icon: 'printer',
        click: (reports) => {
            Dialog.confirm({
                message: 'האם ברצונך להוריד את הדוחות המסומנים?',
                confirmText: 'כן',
                cancelText: 'לא',
            })
                .then(({ result }) => {
                    if (result) {
                        const loading = Loading.open({
                            isFullPage: true,
                        });

                        LeasingCompaniesService
                            .downloadTransfer(reports.map((item) => item.PdfUrl))
                            .then((r) => {
                                if (r.data) {
                                    const blob = new Blob([r.data], { type: 'application/pdf' });
                                    saveAs(blob, 'הסבה.pdf');
                                }
                            })
                            .catch(() => {
                                Toast.open({
                                    type: 'is-danger',
                                    message: 'לא היה ניתן לבצע את הפעולה, נסה שוב.',
                                    duration: 8000,
                                });
                            })
                            .finally(() => {
                                loading.close();
                            });
                    }
                });
        },
    },
    printDriverTransfers: {
        Id: 'PrintDriverTransfers',
        Label: 'הדפסה',
        icon: 'printer',
        click: (reports) => {
            Dialog.confirm({
                message: 'האם ברצונך להדפיס את הדוחות המסומנים?',
                confirmText: 'כן',
                cancelText: 'לא',
            })
                .then(({ result }) => {
                    if (result) {
                        const loading = Loading.open({
                            isFullPage: true,
                        });

                        DriverService
                            .printTransfers(reports.map((item) => item.Id))
                            .then((r) => {
                                printer.printHtml(r.data.Html, null, null, () => {
                                    Dialog.confirm({
                                        message: 'האם לסמן את הדוחות כנשלחו להדפסה?',
                                        confirmText: 'כן',
                                        cancelText: 'לא',
                                    })
                                        .then((toPrint) => {
                                            if (toPrint.result) {
                                                LeasingCompaniesService
                                                    .setTransferStatus({
                                                        TransferStatus: 'Printed',
                                                        ReportIds: reports.map((i) => i.Id),
                                                    }).then(() => {
                                                        Toast.open({
                                                            type: 'is-success',
                                                            message: 'סטטוס הדוחות שונה להודפסו!',
                                                            duration: 4000,
                                                        });
                                                    });
                                            }
                                        });
                                });
                            })
                            .catch(() => {
                                Toast.open({
                                    type: 'is-danger',
                                    message: 'לא היה ניתן לבצע את הפעולה, נסה שוב.',
                                    duration: 8000,
                                });
                            })
                            .finally(() => {
                                loading.close();
                            });
                    }
                });
        },
    },
    signOnReports: {
        Id: 'SignOnReports',
        Label: 'החתמת דוחות',
        icon: 'draw',
        click: (reports, onSuccess) => {
            Modal.open({
                component: DialogSign,
                props: {
                    reports: reports.map((r) => r.Id),
                    onSuccess,
                    title: "חתימה על הסבות",
                    signCase: "leasingCompantLawyerSign"
                },
                canCancel: false,
                hasModalCard: true,
                trapFocus: true,
            });
        },
    },
    createTransfersCompany: {
        Id: 'CreateTransfersCompany',
        Label: 'הפקת מסמכי הסבה',
        icon: 'send',
        click: (reports) => {
            Dialog.confirm({
                message: 'האם אתה בטוח שברצונך להפיק מסמכי הסבה לדוחות המסומנים?',
                onConfirm: () => {
                    const loading = Loading.open();
                    LeasingCompaniesService
                        .transferReports(reports.map((r) => r.Id))
                        .then(() => {
                            Toast.open({
                                type: 'is-success',
                                message: 'הפעולה בוצעה!',
                                duration: 4000,
                            });
                        })
                        .catch(() => {
                            Toast.open({
                                type: 'is-danger',
                                message: 'לא היה ניתן לבצע את הפעולה!',
                                duration: 8000,
                            });
                        })
                        .finally(() => {
                            loading.close();
                        });
                },
                confirmText: 'בטוח!',
                cancelText: 'בטל',
            });
        },
    },
    createTransfersDriver: {
        Id: 'CreateTransfersDriver',
        Label: 'הפקת מסמכי הסבת נהג',
        icon: 'send',
        click: (reports) => {
            Dialog.confirm({
                message: 'האם אתה בטוח שברצונך להפיק מסמכי הסבה לדוחות המסומנים?',
                onConfirm: () => {
                    const loading = Loading.open();
                    DriverService
                        .transferReportDriver(reports.map((r) => r.Id))
                        .then(() => {
                            Toast.open({
                                type: 'is-success',
                                message: 'הפעולה בוצעה!',
                                duration: 4000,
                            });
                        })
                        .catch(() => {
                            Toast.open({
                                type: 'is-danger',
                                message: 'לא היה ניתן לבצע את הפעולה!',
                                duration: 8000,
                            });
                        })
                        .finally(() => {
                            loading.close();
                        });
                },
                confirmText: 'בטוח!',
                cancelText: 'בטל',
            });
        },
    },
    tollRoadStatus: {
        Id: 'TollRoadStatus',
        Label: 'שינוי סטטוס',
        icon: 'credit-card-check',
        click: (reports, onSuccess) => {
            Modal.open({
                component: DialogTollRoadStatus,
                canCancel: false,
                hasModalCard: true,
                trapFocus: true,
                props: {
                    reports: reports.map((r) => r.Id),
                    onSuccess,
                },
            });
        },
    },
    requestDriverSignature: {
        Id: 'RequestDriverSignature',
        Label: 'בקשת חתימה מהנהג',
        icon: 'draw',
        click: (reports) => {
            Dialog.confirm({
                message: 'האם ברצונך לשלוח בקשת חתימה לנהג עבור הדוחות המסומנים?',
                confirmText: 'כן',
                cancelText: 'לא',
            })
                .then(({ result }) => {
                    if (result) {
                        const loading = Loading.open({
                            isFullPage: true,
                        });
                        Promise.all(reports.map((r) => AccidentService
                            .requestSignature(r.Id)))
                            .then(() => {
                                Toast.open({
                                    type: 'is-success',
                                    message: 'הבקשה נשלחה!',
                                    duration: 4000,
                                });
                            })
                            .catch(() => {
                                Toast.open({
                                    type: 'is-danger',
                                    message: 'לא היה ניתן לבצע את הפעולה, נסה לאשר דוחות שוב.',
                                    duration: 8000,
                                });
                            })
                            .finally(() => {
                                loading.close();
                            });
                    }
                });
        },
    },
    updateExcludedVehicles: {
        Id: 'updateExcludedVehicles',
        Label: 'עדכון רכבים לא מנוהלים',
        icon: 'flag-variant-outline',
        click: (vehicles) => {
            Dialog.confirm({
                message: 'האם אתה בטוח שברצונך לגרוע את הרכבים ממצבת הרכבים?',
                onConfirm: () => {
                    const loading = Loading.open();
                    const boolVar = vehicles.some(
                        (value) => typeof value === "object");
                    const vehivlesToSend = { PlateNumbers: boolVar ? vehicles.map((v) => v.PlateNumber) : vehicles };
                    VehicleService
                        .updateExcludedVehicles(vehivlesToSend)
                        .then(() => {
                            Toast.open({
                                type: 'is-success',
                                message: 'הפעולה בוצעה!',
                                duration: 4000,
                            });
                        })
                        .catch(() => {
                            Toast.open({
                                type: 'is-danger',
                                message: 'לא היה ניתן לבצע את הפעולה!',
                                duration: 8000,
                            });
                        })
                        .finally(() => {
                            loading.close();
                        });
                },
                confirmText: 'בטוח!',
                cancelText: 'בטל',
            });
        },
    },
    removeExcludedVehicles: {
        Id: 'removeExcludedVehicles',
        Label: 'ביטול גריעה',
        icon: 'undo',
        click: (vehicles, onSuccess) => {
            Dialog.confirm({
                message: 'האם אתה בטוח שברצונך לבצע ביטול גריעה לרכבים ?',
                onConfirm: () => {
                    const loading = Loading.open();
                    const boolVar = vehicles.some(
                        (value) => typeof value === "object");
                    const vehivlesToSend = { PlateNumbers: boolVar ? vehicles.map((v) => v.PlateNumber) : vehicles };
                    VehicleService
                        .removeExcludedVehicles(store.state.auth.profileId, vehivlesToSend)
                        .then(() => {
                            Toast.open({
                                type: 'is-success',
                                message: 'הפעולה בוצעה!',
                                duration: 4000,
                            });
                            onSuccess();
                        })
                        .catch(() => {
                            Toast.open({
                                type: 'is-danger',
                                message: 'לא היה ניתן לבצע את הפעולה!',
                                duration: 8000,
                            });
                        })
                        .finally(() => {
                            loading.close();
                        });
                },
                confirmText: 'בטוח!',
                cancelText: 'בטל',
            });
        },
    },
};

export default actions;
