<template>
    <div class="DialogUpdateNote__Component">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">עדכון משימה </p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">
                <div class="DialogUpdateNote__Component__Loading">
                    <FieldInlineDateTime label="עד תאריך" @onChange="onChange" :hide-hover="true" position="is-bottom-left"
                        v-model="DueDate" />
                    <FieldInlineDateTime label="תזכורת" @onChange="onChange" :hide-hover="true" position="is-bottom-left"
                        v-model="EmailReminderDate" />
                    <b-field label="הערה" :type="!isValidReference ? 'is-danger' : ''"
                        :message="!isValidReference ? 'יש להזין הערה' : ''">
                        <b-input v-model="note"></b-input>
                    </b-field>
                </div>
            </section>
            <footer class="modal-card-foot">
                <b-button :loading="isLoading" @click="save" label="שמור" type="is-primary" />
                <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
            </footer>
        </div>
    </div>
</template>

<script>
import EntityItemsService from "@/services/EntityItemsService";
import FieldInlineDateTime from "@/components/Fields/FieldInlineDateTime.vue";
import { ToastProgrammatic as Toast } from "buefy";

export default {
    name: "DialogUpdateNote",
    props: ["tasks", "onSuccess"],
    components: {
        FieldInlineDateTime,
    },
    computed: {
        isValidReference() {
            if (this.submitted) {
                return this.note;
            }
            return true;
        },
    },
    data() {
        return {
            isLoading: false,
            submitted: false,
            note: null,
            DueDate: null,
            EmailReminderDate: null
        };
    },
    methods: {
        save() {
            this.submitted = true;
            if (this.isValidReference) {
                this.isLoading = true;
                const reqs = this.tasks.map((t) =>
                    EntityItemsService.editTask(
                        t.EntityType,
                        t.EntityId,
                        t.Id,
                        {
                            ...t,
                            Body: this.note || t.Body,
                            DueDate: this.DueDate || t.DueDate,
                            EmailReminderDate: this.EmailReminderDate || t.EmailReminderDate
                        }
                    )
                );
                Promise.all(reqs)
                    .then((r) => {
                        console.log(r);
                        this.$emit("close");
                        if (this.onSuccess) this.onSuccess();
                        Toast.open({
                            type: "is-success",
                            message: "הפעולה בוצעה!",
                            duration: 4000,
                        });
                    })
                    .catch(() => {
                        Toast.open({
                            type: "is-danger",
                            message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
                            duration: 8000,
                        });
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            }
        },
        onChange() {
            this.$emit('onFormChange', this.note);
        }
    },
};
</script>

<style lang="scss">

.datetime .dropdown-content {
    width: 25%;

    .field-body .field {
        flex-direction: row-reverse;
    }
}
</style>

<style scoped>

.modal-card {
    height: 600px;
}
</style>
