var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "DialogReportPaid__Component" },
    [
      _vm.isModalOpen
        ? _c("NewActivity", {
            ref: "taskActivity",
            attrs: {
              type: "Task",
              propPriority: _vm.taskReminderObj.Priority,
              propTitle: _vm.getTitle,
              propReminder: _vm.getReminder,
              propAssignedUserId: _vm.taskReminderObj.AssignedUserId,
              "show-title": "true",
              propBody: _vm.taskReminderObj.Body,
              propisAdminWithoutProfileId: _vm.isAdminWithoutProfileId
            },
            on: { onClose: _vm.onTaskModelClose, onSave: _vm.saveTask }
          })
        : _vm._e(),
      _c("div", { staticClass: "modal-card" }, [
        _c("header", { staticClass: "modal-card-head" }, [
          _c("p", { staticClass: "modal-card-title" }, [
            _vm._v("שינוי סטטוס הסבת חברה")
          ]),
          _c("button", {
            staticClass: "delete",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          })
        ]),
        _c("section", { staticClass: "modal-card-body" }, [
          _c(
            "div",
            { staticClass: "DialogReportPaid__Component__Loading" },
            [
              _c(
                "b-field",
                { attrs: { label: "אסמכתה/הערה/דואר רשום" } },
                [
                  _c("b-input", {
                    model: {
                      value: _vm.reference,
                      callback: function($$v) {
                        _vm.reference = $$v
                      },
                      expression: "reference"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-field",
                {
                  attrs: {
                    label: "סטטוס הסבה",
                    type: !_vm.isStatusValid ? "is-danger" : "",
                    message: !_vm.isStatusValid ? "יש לבחור סטטוס הסבה" : ""
                  }
                },
                [
                  _c(
                    "v-select",
                    {
                      attrs: {
                        dir: "rtl",
                        label: "Text",
                        options: _vm.data,
                        "append-to-body": "",
                        clearable: true,
                        placeholder: "בחר סטטוס מהרשימה",
                        reduce: function(item) {
                          return item.Value
                        }
                      },
                      on: { input: _vm.setSelected }
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "no-options" }, slot: "no-options" },
                        [_vm._v("לא נמצאו ערכים דומים")]
                      )
                    ]
                  )
                ],
                1
              ),
              _vm.statusId
                ? _c(
                    "div",
                    { staticClass: "activity-container" },
                    [
                      _c(
                        "b-checkbox",
                        {
                          staticClass: "checkbox",
                          attrs: {
                            loading: _vm.isLoading,
                            label: "",
                            type: "is-link is-light"
                          },
                          nativeOn: {
                            click: function($event) {
                              return _vm.onIsCreateTask($event)
                            }
                          },
                          model: {
                            value: _vm.isCreateTask,
                            callback: function($$v) {
                              _vm.isCreateTask = $$v
                            },
                            expression: "isCreateTask"
                          }
                        },
                        [_vm._v(" צור משימה ")]
                      ),
                      typeof _vm.taskReminderObj === "object" &&
                      _vm.taskReminderObj !== null
                        ? _c(
                            "a",
                            {
                              on: {
                                click: function($event) {
                                  _vm.isCreateTask = true
                                  _vm.isModalOpen = true
                                }
                              }
                            },
                            [_vm._v("ערוך")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ]),
        _c(
          "footer",
          { staticClass: "modal-card-foot" },
          [
            _c("b-button", {
              attrs: {
                loading: _vm.isLoading,
                label: "שמור",
                type: "is-primary"
              },
              on: { click: _vm.save }
            }),
            _c("b-button", {
              attrs: { disabled: _vm.isLoading, label: "סגור" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }