var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "DialogReportPay__Component" }, [
    _c("div", { staticClass: "modal-card", class: { ready: !_vm.isLoading } }, [
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [_vm._v("תשלום דוחות")]),
        _c("button", {
          staticClass: "delete",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        })
      ]),
      _c("section", { staticClass: "modal-card-body" }, [
        _c(
          "div",
          { staticClass: "DialogReportPay__Component__Loading" },
          [
            _c("transition", { attrs: { name: "fade" } }, [
              _vm.showLoading
                ? _c("h1", [
                    _vm._v(" " + _vm._s(_vm.loading[_vm.sentenceIndex]) + " ")
                  ])
                : _vm._e()
            ]),
            _c(
              "div",
              [
                _c("b-loading", {
                  attrs: { "is-full-page": false, "can-cancel": false },
                  model: {
                    value: _vm.isLoading,
                    callback: function($$v) {
                      _vm.isLoading = $$v
                    },
                    expression: "isLoading"
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "footer",
        { staticClass: "modal-card-foot" },
        [
          _c("transition", { attrs: { name: "fade" } }, [
            !_vm.isLoading
              ? _c(
                  "div",
                  [
                    _c("b-button", {
                      attrs: { label: "שלם", type: "is-primary" }
                    }),
                    _c("b-button", {
                      attrs: { label: "סגור" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("close")
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }