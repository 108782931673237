<template>
  <div class="DialogReportPaid__Component">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">סטטוח דוח</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <h1>נא לבחור את הסטטוס אשר ישוייך אל הדוחות המסומנים</h1>
        <div class="DialogReportPaid__Component__Loading">
          <v-select
            dir="rtl"
            label="Text"
            :options="data"
            append-to-body
            :clearable="true"
            :placeholder="'בחר סטטוס מהרשימה'"
            :reduce="(item) => item.Value"
            @input="setSelected"
          >
            <div slot="no-options">לא נמצאו ערכים דומים</div>
          </v-select>
        </div>
      </section>
      <footer class="modal-card-foot">
        <b-button
          :loading="isLoading"
          :disabled="!statusId"
          @click="save"
          label="שמור"
          type="is-primary"
        />
        <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
      </footer>
    </div>
  </div>
</template>

<script>
import TollRoadStatusesService from "@/services/TollRoadStatusesService";
import TollRoadReportsService from "@/services/TollRoadReportsService";
import vSelect from "vue-select";
import "vue-select/src/scss/vue-select.scss";
import { ToastProgrammatic as Toast } from "buefy";

export default {
  name: "DialogTollRoadStatus",
  props: ["reports", "onSuccess"],
  components: {
    vSelect,
  },
  computed: {},
  created() {
    TollRoadStatusesService.get().then((r) => {
      this.data = r;
    });
  },
  data() {
    return {
      isLoading: false,
      statusId: null,
      data: [],
    };
  },
  methods: {
    setSelected(value) {
      this.statusId = value;
    },
    save() {
      this.isLoading = true;
      TollRoadReportsService.setStatus({
        ReportIds: this.reports,
        Status: this.statusId,
      })
        .then(() => {
          this.$emit("close");
          if (this.onSuccess) this.onSuccess();
          Toast.open({
            type: "is-success",
            message: "הפעולה בוצעה!",
            duration: 4000,
          });
        })
        .catch(() => {
          Toast.open({
            type: "is-danger",
            message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
            duration: 8000,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
</style>
